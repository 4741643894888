* {
  font-family: Arial, Helvetica, sans-serif;
}

.App {
  margin: 0rem;
  padding: 0rem;
  height: auto;
  padding-bottom: 10px;
}

body {
  margin: 0;
  padding: 0;
}

.container {
  text-align: start;
  margin-bottom: 0.5rem;
}

.App span {
  font-size: 1vw;
  font-weight: 500;
}

h3 {
  font-size: 2vw;
}

@media (width<600px) {
  .App span {
    font-size: 3vw;
    font-weight: 500;
  }

  h3 {
    font-size: 3vw;
  }
}

.label p {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 400;
  width: 6rem;
}

.labels {
  width: 40vw;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: auto;
  font-size: 2vw;
  row-gap: 1rem;
  column-gap: 6.5rem;
}
.label:nth-child(3n-2) {
  border: 3px solid #c9c8c6;
  border-right: none;
  border-top: none;
  border-bottom: none;
  padding-left: 0.7rem;
}

.chartcontainer {
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-top: 3rem;
}

.legend {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.dot-legend {
  height: 0.8rem;
  width: 0.8rem;
  margin-right: 0.2rem;
  border-radius: 50%;
}
.legendscontainer {
  display: flex;
}

/*navbar*/

.Navbar {
  list-style-type: none;
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  overflow: hidden;
  background-color: #333;
}

.Navbar li {
  float: left;
}

.Navbar li .link {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.active {
  background-color: #35a556;
}

.logout {
  background-color: rgba(255, 51, 51, 0.966)
}

.logOutIcon path{
  stroke: white;
}

/*admin dashbaord*/

.files_table {
  width: 90%;
  margin: auto;
}

.table {
  width: 100%;
  text-align: center;
}

.table .view {
  text-align: center;
  font-size: 19px;
  color: #fff;
  background-color: #35a556;
  width: 70%;
  border: none;
  border-radius: 4px;
  padding: 0.3em;
  margin-left: 0.3rem;
  cursor: pointer;
  overflow: hidden;
}

.table .delete {
  text-align: center;
  font-size: 19px;
  color: #fff;
  background-color: rgb(255, 62, 62);
  width: 70%;
  border: none;
  border-radius: 4px;
  padding: 0.3em;
  margin-left: 0.3rem;
  cursor: pointer;
  overflow: hidden;
}

/* Custom checkbox */
.custom-checkbox {
  position: relative;
}
.custom-checkbox input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  margin: 5px 0 0 3px;
  z-index: 9;
}
.custom-checkbox label:before {
  width: 18px;
  height: 18px;
}
.custom-checkbox label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  background: white;
  border: 1px solid #bbb;
  border-radius: 2px;
  box-sizing: border-box;
  z-index: 2;
}
.custom-checkbox input[type="checkbox"]:checked + label:after {
  content: "";
  position: absolute;
  left: 6px;
  top: 3px;
  width: 6px;
  height: 11px;
  border: solid #000;
  border-width: 0 3px 3px 0;
  transform: inherit;
  z-index: 3;
  transform: rotateZ(45deg);
}

.custom-checkbox input[type="checkbox"]:checked + label:before {
  border-color: orange;
  background: orange;
}
.custom-checkbox input[type="checkbox"]:checked + label:after {
  border-color: #fff;
}
.custom-checkbox input[type="checkbox"]:disabled + label:before {
  color: #b8b8b8;
  cursor: auto;
  box-shadow: none;
  background: #ddd;
}
/* Modal styles */
.modal .modal-dialog {
  max-width: 400px;
}
.modal .modal-header,
.modal .modal-body,
.modal .modal-footer {
  padding: 20px 30px;
}
.modal .modal-content {
  border-radius: 3px;
}
.modal .modal-footer {
  background: #ecf0f1;
  border-radius: 0 0 3px 3px;
}
.modal .modal-title {
  display: inline-block;
}
.modal .form-control {
  border-radius: 2px;
  box-shadow: none;
  border-color: #dddddd;
}
.modal textarea.form-control {
  resize: vertical;
}
.modal .btn {
  border-radius: 2px;
  min-width: 100px;
}
.modal form label {
  font-weight: normal;
}

td .deleteAdmin {
  color: gray;
  transition: 1300ms;
}

td .deleteAdmin:hover {
  color: red;
  transform: scale(1.3);
}

/* files table*/

.filesWrapper {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.filesContainer {
  display: flex;
  flex-direction: column;
  border: 3px solid black;
  align-items: center;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  width: 80%;
  box-shadow: 0px 6px 40px 3px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 0px 6px 40px 3px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0px 6px 40px 3px rgba(0, 0, 0, 0.58);
  overflow: hidden;
}

.filesContainer h1 {
  margin: 0;
  display: flex;
  justify-content: center;
  width: 32rem;
  background-color: orange;
  border-bottom: 1px solid black;
}

.file {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 15px;
  width: 100%;
  border-bottom: 1px solid gray;
}

.file a,
.file a {
  font-size: 2rem;
  transition: 1300ms;
}

.file a:nth-child(1):hover {
  color: gold;
  transform: scale(1.3);
}

.file a:nth-child(3):hover {
  color: red;
  transform: scale(1.3);
}

h2 {
  display: flex;
  justify-content: center;
  width: 10rem;
}

/*home page*/

.upload {
  text-align: center;
  font-size: 19px;
  color: #fff;
  background-color: #35a556;
  width: 100px;
  border: none;
  border-radius: 4px;
  padding: 0.3em;
  margin-left: 0.3rem;
  cursor: pointer;
  overflow: hidden;
}

.reset {
  text-align: center;
  font-size: 19px;
  color: #fff;
  background-color: rgb(69, 69, 253);
  width: 100px;
  border: none;
  border-radius: 4px;
  padding: 0.3em;
  margin-left: 0.3rem;
  cursor: pointer;
  overflow: hidden;
}

.inactive_reset {
  text-align: center;
  font-size: 19px;
  color: #fff;
  background-color: grey;
  width: 100px;
  border: none;
  border-radius: 4px;
  padding: 0.3em;
  margin-left: 0.3rem;
  cursor: pointer;
  overflow: hidden;
}

.file_input {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

/*Login page*/

.login_container *,
*::before,
*::after {
  box-sizing: border-box;
}

.error_message {
  color: rgb(252, 77, 77);
  text-align: center;
}

.login_container h1 {
  font-weight: 700;
  color: #384047;
  text-align: center;
  line-height: 1.5em;
  margin-bottom: 0.2em;
  margin-top: 0.2em;
}

.login_container a {
  font-size: 0.98em;
  color: #8a97a0;
  text-decoration: none;
}

.login_container a:hover {
  color: green;
}

.ModalInput, .ModalInput:focus {
  color: #384047;
  background-color: #e8eeef;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
  border: none;
  border-radius: 4px;
  padding: 0.7em;
  margin-bottom: 1.2em;
  width: 100%;
}

.login_container {
  display: flex;
  -webkit-display: box;
  -moz-display: box;
  -ms-display: flexbox;
  -webkit-display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  padding: 6%;
  margin: 0;
}

.login_container form {
  background-color: #fff;
  padding: 2em;
  padding-bottom: 3em;
  border-radius: 8px;
  max-width: 400px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 10px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.login_container form input {
  color: #384047;
  background-color: #e8eeef;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
  border: none;
  border-radius: 4px;
  padding: 1em;
  margin-bottom: 1.2em;
  width: 100%;
}

.login_container form input:focus {
  outline: none;
}

.login_container .button {
  font-weight: 600;
  text-align: center;
  font-size: 19px;
  color: #fff;
  background-color: #35a556;
  width: 100%;
  border: none;
  border-radius: 4px;
  padding: 0.8em;
  margin-top: 1em;
  cursor: pointer;
  overflow: hidden;
  transition: all 200ms ease-in-out;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
}

.login_container .button:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.3);
  transform: translateY(-4px);
}

.login_container .button span {
  position: relative;
  z-index: 1;
}

.login_container .button .circle {
  position: absolute;
  z-index: 0;
  background-color: #35a556;
  border-radius: 50%;
  transform: scale(0);
  opacity: 5;
  height: 50px;
  width: 50px;
}

.login_container .button .circle.animate {
  animation: grow 0.5s linear;
}

@keyframes grow {
  to {
    transform: scale(2.5);
    opacity: 0;
  }
}
